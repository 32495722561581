// import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
// import { useParams, Redirect } from 'react-router-dom';
// import axiosRetry from 'axios-retry';
// import axios from 'axios'
// import { makeUseAxios } from 'axios-hooks'
// import moment from 'moment';
// import { Typography, makeStyles } from '@material-ui/core';

// import PageWrapper from '../components/PageWrapper';
// import Loading from '../components/Loading';
// import EmbeddedPublications from '../components/EmbeddedPublications';
// import { LaptopWindowsRounded } from '@material-ui/icons';

// axiosRetry(axios, { retry: 3 });
// const useAxios = makeUseAxios({
//   axios: axios.create({ baseURL: process.env.REACT_APP_ADMIN_ENDPOINT })
// })

// const useStyles = makeStyles((theme) => ({
//   section: {
//     padding: '128px 64px',
//     boxSizing: 'border-box',
//     alignItems: 'center',
//     display: 'flex',
//     flexDirection: 'column',
//     margin: '0 auto',
//     maxWidth: 1024,
//     [theme.breakpoints.down('sm')]: {
//       padding: '32px 0',
//     },
//   },
//   extraSection: {
//     padding: '64px 0',
//     maxWidth: 1200
//   },
//   excerpt: {
//     padding: '64px'
//   },
//   divider: {
//     borderColor: theme.palette.primary.main,
//     borderTop: 'none',
//     margin: '0 auto',
//     maxWidth: 500
//   },
//   paper: {
//     padding: '42px 64px',
//     zIndex: 2
//   },
//   landingPaper: {
//     backgroundColor: '#EDBA3B',
//     '& h2': {
//       marginBottom: '32px'
//     }
//   },
//   sectionTitle: {
//     padding: '0 64px'
//   },
//   caption: {
//     lineHeight: '2.5rem',
//     color: theme.palette.secondary.light,
//     textTransform: 'uppercase'
//   },
//   rowContent: {
//     flexDirection: 'row',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column',
//       '& h3': {
//         textAlign: 'center'
//       }
//     }
//   }
// }))

const Publications = () => {
  // const classes = useStyles();

  const { slug } = useParams();
  window.location = slug ? `${process.env.REACT_APP_STRATSEA_HOMEPAGE}/${slug}` : process.env.REACT_APP_STRATSEA_HOMEPAGE;
  return null;

  // const [{ data: mainPosts, loading, error }] = useAxios({
  //   url: '/wp-json/wp/v2/posts',
  //   params: { slug: [slug] }
  // })

  // const noData = !loading && (error || !(mainPosts && mainPosts.length > 0));

  // if (noData) {
  //   return <Redirect to="/404" />
  // }

  // let view;
  // if (loading) {
  //   view = (
  //     <section className={classes.section}>
  //       <Loading loadingText="Loading publication, please wait..." />
  //     </section>
  //   )
  // } else {
  //   const [post] = mainPosts;
  //   const { id, title, excerpt, content, date } = post;

  //   const dateInMoment = moment(date);
  //   const parsedDate = dateInMoment.isValid() ? dateInMoment.format('DD MMM YYYY') : '';

  //   view = (
  //     <Fragment>
  //       <section className={classes.section}>
  //         <Typography variant="h3" align="center">{title.rendered}</Typography>
  //         <Typography className={classes.caption} variant="body2">{parsedDate}</Typography>
  //         {excerpt && (
  //           <div className={classes.excerpt}>
  //             <hr className={classes.divider} />
  //             <Typography variant="h6" align="center" dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
  //             <hr className={classes.divider} />
  //           </div>
  //         )}
  //         <Typography
  //           variant="body1"
  //           color="textPrimary"
  //           dangerouslySetInnerHTML={{ __html: content.rendered }}
  //         />
  //       </section>
  //       <hr className={classes.divider} />
  //       <section id="publications" style={{ paddingTop: 80 }} className={`${classes.section} ${classes.extraSection}`}> 
  //         <Typography
  //           variant="h3"
  //           color="textPrimary"
  //           className={classes.sectionTitle}
  //           align="center"
  //         >
  //           Other Publications
  //         </Typography>
  //         <EmbeddedPublications excludedId={id} />
  //       </section>
  //     </Fragment>
  //   )
  // }

  // return (
  //   <PageWrapper>
  //     {view}
  //   </PageWrapper>
  // )
}

export default Publications;