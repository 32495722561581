import React from 'react';
import moment from 'moment';

import { Button, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: (props) => props.big ? 0 : 12,
    padding: (props) => props.big ? 64 : 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: (props) => props.big ? '50vh' : 250,
    flexGrow: 1,
    flexBasis: 125,
    '& a': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: 0
    },
    [theme.breakpoints.down('sm')]: {
      height: (props) => props.big ? '50vh' : 175
    }
  },
  caption: {
    lineHeight: '1.75rem',
    color: theme.palette.secondary.light,
    textTransform: 'uppercase'
  },
  excerpt: {
    fontFamily: theme.typography.body1.fontFamily,
    fontWeight: 300
  }
}))

const PublicationCard = ({ big, title, excerpt, slug, url, date }) => {
  const classes = useStyles({ big });

  const parsedExcerpt = (excerpt && excerpt.rendered) ? (excerpt.rendered.replace(/<(|\/)p>/g, '')) : '';
  let truncatedExcerpt = parsedExcerpt;
  const stringLimit = big ? 350 : 200;
  if (parsedExcerpt.length > stringLimit) {
    truncatedExcerpt = parsedExcerpt.substring(0, stringLimit);
    const lastSpace = truncatedExcerpt.lastIndexOf(' ');
    truncatedExcerpt = `${truncatedExcerpt.substring(0, lastSpace)}...`;
  }

  const dateInMoment = moment(date);
  const parsedDate = dateInMoment.isValid() ? dateInMoment.format('DD MMM YYYY') : ''

  return (
    <Paper className={classes.card}>
      <div>
        <Typography variant={big ? 'h3' : 'h6'}>{title}</Typography>
        <Typography className={classes.caption} variant={big ? 'body2' : 'caption'}>{parsedDate}</Typography>
      </div>
      {big && <Typography className={classes.excerpt} variant="body1">{truncatedExcerpt}</Typography>}
      <Button fullWidth={false} href={url || `/publications/${slug}`} target="_blank" color="primary">READ MORE</Button>
    </Paper>
  )
}

export default PublicationCard;