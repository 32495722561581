import React from 'react';
import axiosRetry from 'axios-retry';
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

import PublicationList from './PublicationList';
import Loading from './Loading';
import Error from './Error';

axiosRetry(axios, { retry: 3 });
const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: process.env.REACT_APP_STRATSEA_ENDPOINT })
})

const EmbeddedPublications = ({ excludedId }) => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: 'https://stratsea.com/wp-json/wp/v2/posts',
    params: { per_page: 4 }
  })

  if (loading) return <Loading loadingText="Loading publications, please wait..." />
  if (error) return <Error showRetry onRetry={refetch} />

  return (<PublicationList publications={data || []} moreButtonType="go-to" />);
}

export default EmbeddedPublications;