import React from 'react';
import { ReactComponent as AnimatedLogo } from '../assets/logo-only-animated.svg';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '64px',
    margin: '0 auto',
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
  heading: {
    marginRight: 16
  }
}))

const Loading = ({ loadingText }) => {
  const classes = useStyles();

  return (
  <div className={classes.section}>
    <Typography color="primary" className={classes.heading} variant="h5">
      {loadingText || 'Loading, please wait...'}
    </Typography>
    <AnimatedLogo width={50} height={50} />
  </div>
)
}

export default Loading;