import React from 'react';

import { Button, makeStyles, Box, Grid } from '@material-ui/core';
import PublicationCard from './PublicationCard';

const useStyles = makeStyles(() => ({
  listContent: {
    padding: '32px',
    margin: '0 auto',
    maxWidth: 1200,
    justifyContent: 'stretch'
  },
  bottomButton: {
    margin: '24px 0'
  }
}))

const PublicationList = ({ disableMoreButton, loading, onMoreButtonClick, moreButtonType, publications }) => {
  const classes = useStyles({ moreButtonType });

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.listContent}>
      <Grid container alignItems="center">
        {(publications && publications.length) > 0 && (
          publications.map(({ title, excerpt, slug, link, date }) => (
            <Grid item md={3} sm={6} xs={12}>
              <PublicationCard
                title={title?.rendered}
                excerpt={excerpt}
                slug={slug}
                url={link}
                date={date}
              />
            </Grid>
          ))
        )}
      </Grid>
      {moreButtonType === 'go-to' && (
        <Button className={classes.bottomButton} disabled={disableMoreButton || loading} color="primary" href="https://stratsea.com" target="_blank">
          SEE MORE PUBLICATIONS
        </Button>
      )}
      {moreButtonType === 'load-more' && (
        <Button className={classes.bottomButton} disabled={disableMoreButton || loading} color="primary" onClick={onMoreButtonClick}>
          {loading && 'LOADING MORE PUBLICATIONS...'}
          {disableMoreButton && 'NO MORE PUBLICATIONS TO DISPLAY'}
          {!(loading || disableMoreButton) && 'LOAD MORE PUBLICATIONS'}
        </Button>
      )}
    </Box>
  )
}

export default PublicationList;