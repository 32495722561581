import React, { Fragment, useState, useEffect } from 'react';
import axiosRetry from 'axios-retry';
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

import { Typography, makeStyles, Grid, Button, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ReactComponent as Logo } from '../assets/logo.svg';

axiosRetry(axios, { retry: 3 });
const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: process.env.REACT_APP_ADMIN_ENDPOINT })
})

const useStyles = makeStyles((theme) => ({
  section: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'stretch',
    background: 'linear-gradient(rgba(58, 47, 17, 0.6), rgba(58, 47, 17, 0.6)), url("https://source.unsplash.com/w74T9SFYyHc/1920x1280") center',
    backgroundSize: 'cover'
  },
  navMenu: {
    color: theme.palette.primary.main,
    padding: 0,
    marginBottom: 12,
    borderRadius: 0,
    justifyContent: 'flex-start',
    borderBottom: '1px solid transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderBottom: '1px solid'
    }
  },
  subsection: {
    padding: '64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  address: {
    paddingTop: 32,
    color: 'white',
    whiteSpace: 'pre'
  },
  contactUsForm: {
    backgroundColor: theme.palette.primary.main
  },
  form: {
    margin: '16px -8px'
  },
  input: {
    borderRadius: 2,
    backgroundColor: 'white',
    padding: 12,
    margin: 8,
    boxShadow: theme.shadows[1],
    boxSizing: 'border-box',
    '& input': {
      padding: 0
    }
  },
  adjustWithInput: {
    margin: '-8px 8px 8px'
  },
  alert: {
    margin: '0 8px'
  },
  submitButton: {
    padding: 12,
    borderRadius: 2,
    margin: 8
  },
  inputFocused: {
    boxShadow: theme.shadows[3]
  }
}))

const ContactUs = ({ isHome }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const [warning, setWarning] = useState('');

  const { fullName, email, subject, phone, body } = values;

  const [{ data }] = useAxios({
    url: '/wp-json/company/v1/address'
  })

  const { company_name, address, country, postal_code } = data || {};

  const [{ data: submittingSuccess, loading: submitting, error: submittingError }, sendEmail] = useAxios({
    url: '/wp-json/contact/v1/send',
    method: 'POST',
    data: {
      full_name: fullName,
      email,
      subject,
      phone,
      body
    }
  }, {
    manual: true
  })

  useEffect(() => {
    if (submittingSuccess && !submitting) {
      setSuccess('Message successfully sent!');
    } else if (submittingError && !submitting) {
      setWarning('An error occured. Please try again.');
    }
  }, [submittingSuccess, submitting, submittingError])


  const handleInputChange = (key) => (event) => {
    const { value } = event.target;
    setValues({ ...values, [key]: value })
  }

  const onCloseAlert = () => {
    setSuccess('');
    setWarning('');
  }

  const validateEmail = (event) => {
    event.preventDefault();
    setSuccess('');
    setWarning('');

    const inputErrors = {};

    if (!fullName) {
      inputErrors.fullName = 'Please provide your full name!';
    }
    if (!email) {
      inputErrors.email = 'Please provide your email address!';
    }
    if (!phone) {
      inputErrors.phone = 'Please provide your phone number!';
    }
    if (!subject) {
      inputErrors.subject = 'Please provide your message subject!';
    }
    if (!body) {
      inputErrors.body = 'Please provide your message content';
    }

    setErrors(inputErrors);

    if (Object.keys(inputErrors).length === 0) {
      sendEmail();
    }

  }

  return (
    <Fragment>
      <section id="contact-us" className={classes.section}>
        <Grid container alignItems="stretch" flex={1}>
          <Grid className={classes.subsection} item md={4} sm={6} xs={12}>
            <a href="/">
              <Logo width={300} height="auto" />
            </a>
            <Typography variant="body1" className={classes.address}>
              {company_name}
              <br />
              {address}
              <br />
              {country} {postal_code}
            </Typography>
          </Grid>
          <Grid className={classes.subsection} item md={3} sm={6} xs={12}>
            <Button className={classes.navMenu} href="/#who-we-are" >Who We Are</Button>
            <Button className={classes.navMenu} href="/#capabilities" >Capabilities</Button>
            <Button className={classes.navMenu} href={isHome ? '/#publications' : '/publications'} >Publications</Button>
          </Grid>
          <Grid alignItems="center" className={`${classes.subsection} ${classes.contactUsForm}`} item md={5} xs={12}>
            <Typography variant="h3">Contact Us</Typography>
            <form className={classes.form} onSubmit={validateEmail}>
              <TextField
                placeholder="Full Name"
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.input, focused: classes.inputFocused }
                }}
                FormHelperTextProps={{ className: classes.adjustWithInput }}
                fullWidth
                value={fullName}
                onChange={handleInputChange('fullName')}
                error={!!errors.fullName}
                helperText={errors.fullName}
              />
              <TextField
                placeholder="Phone Number"
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.input, focused: classes.inputFocused }
                }}
                FormHelperTextProps={{ className: classes.adjustWithInput }}
                fullWidth
                value={phone}
                onChange={handleInputChange('phone')}
                error={!!errors.phone}
                helperText={errors.phone}
              />
              <TextField
                placeholder="Email Address"
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.input, focused: classes.inputFocused }
                }}
                FormHelperTextProps={{ className: classes.adjustWithInput }}
                fullWidth
                value={email}
                onChange={handleInputChange('email')}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                placeholder="Subject"
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.input, focused: classes.inputFocused }
                }}
                FormHelperTextProps={{ className: classes.adjustWithInput }}
                fullWidth
                value={subject}
                onChange={handleInputChange('subject')}
                error={!!errors.subject}
                helperText={errors.subject}
              />
              <TextField
                placeholder="Message"
                InputProps={{
                  disableUnderline: true,
                  classes: { root: classes.input, focused: classes.inputFocused }
                }}
                FormHelperTextProps={{ className: classes.adjustWithInput }}
                multiline
                rows={8}
                fullWidth
                value={body}
                onChange={handleInputChange('body')}
                error={!!errors.body}
                helperText={errors.body}
              />
              {!!warning && <Alert className={classes.alert} severity="error" onClose={onCloseAlert}>{warning}</Alert>}
              {!!success && <Alert className={classes.alert} severity="success" onClose={onCloseAlert}>{success}</Alert>}
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={submitting}
                className={classes.submitButton}
                fullWidth
              >
                {submitting ? 'SENDING YOUR MESSAGE...': 'SEND MESSAGE'}
              </Button>
            </form>
          </Grid>
        </Grid>
      </section>
    </Fragment>
  )
}

export default ContactUs;