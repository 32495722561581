import React from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';
import PageWrapper from '../components/PageWrapper';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '64px',
    minHeight: '90vh',
    margin: '0 auto',
    maxWidth: 960,
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
  heading: {
    marginBottom: 32,
    color: theme.palette.secondary.light,
  }
}))

const Page404 = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <PageWrapper>
      <div className={classes.section}>
        <Typography color="secondary" className={classes.heading} variant="h3">This page is not available</Typography>
        <Button variant="text" onClick={history.goBack} color="primary">BACK TO PREVIOUS PAGE</Button>
      </div>
    </PageWrapper>
  )
}

export default Page404;