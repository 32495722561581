import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import Home from './views/Home';
import Page404 from './views/Page404';
import Publications from './views/Publications';
import Publication from './views/Publication';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: '#EDBA3B',
      contrastText: '#3A2F11'
    },
    secondary: {
      main: '#3A2F11',
      light: '#79622A',
      contrastText: '#EDBA3B'
    }
  },
  shadows: [
    'none',
    '0px 0px 24px rgba(58, 47, 17, 0.2)',
    '0px 0px 28px rgba(58, 47, 17, 0.4)',
    '0px 0px 32px rgba(58, 47, 17, 0.6)'
  ],
  typography: {
    fontFamily: '"Noto Serif", serif',
    h1: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: "bold"
    },
    h2: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: "bold"
    },
    h3: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '2.5rem',
      fontWeight: "bold"
    },
    h4: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: "bold"
    },
    h5: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: "bold"
    },
    h6: {
      fontFamily: '"Playfair Display", serif',
      fontWeight: "bold",
      lineHeight: '1.75rem'
    },
    body1: {
      fontWeight: 300
    },
    body2: {
      fontWeight: 300
    },
    button: {
      letterSpacing: '0.03em',
      fontWeight: "bold"
    }
  }
})

const App = () => {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route path="/404" component={Page404} />
          <Route path="/publications/:slug" component={Publication} />
          <Route path="/publications" exact component={Publications} />
          <Route exact path="/" component={Home} />
          <Redirect to='/404' />
        </Switch>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
