// import React, { Fragment, useState, useEffect } from 'react';
// import axiosRetry from 'axios-retry';
// import axios from 'axios'
// import { makeUseAxios } from 'axios-hooks'
// import { Typography, makeStyles, Paper, Hidden, Grid, useMediaQuery, useTheme } from '@material-ui/core';

// import PageWrapper from '../components/PageWrapper';
// import PublicationList from '../components/PublicationList';
// import Loading from '../components/Loading';
// import Error from '../components/Error';
// import PublicationCard from '../components/PublicationCard';

// axiosRetry(axios, { retry: 3 });
// const useAxios = makeUseAxios({
//   axios: axios.create({ baseURL: process.env.REACT_APP_ADMIN_ENDPOINT })
// })

// const useStyles = makeStyles((theme) => ({
//   section: {
//     padding: '64px 0',
//     boxSizing: 'border-box',
//     alignItems: 'center',
//     [theme.breakpoints.down('sm')]: {
//       padding: '32px 0',
//     },
//   },
//   paper: {
//     padding: '42px 64px',
//     zIndex: 2
//   },
//   landingSection: {
//     margin: '128px 0',
//     display: 'flex',
//     justifyContent: 'stretch',
//     [theme.breakpoints.down('xs')]: {
//       backgroundColor: '#EDBA3B'
//     },
//   },
//   landingPaper: {
//     backgroundColor: '#EDBA3B',
//     '& h2': {
//       marginBottom: '32px'
//     }
//   },
//   sectionTitle: {
//     padding: '0 64px'
//   },
//   rowContent: {
//     flexDirection: 'row',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column',
//       '& h3': {
//         textAlign: 'center'
//       }
//     }
//   }
// }))

const Publications = () => {
  window.location = process.env.REACT_APP_STRATSEA_HOMEPAGE;
  return null;

  // const [maxPage, setMaxPage] = useState(1);
  // const [page, setPage] = useState(1);
  // const [allPosts, setAllPosts] = useState([]);
  // const classes = useStyles();

  // const theme = useTheme();
  // const showFeaturedInBig = useMediaQuery(theme.breakpoints.up('sm'));
  // const [{ data, response, loading, error }] = useAxios({
  //   url: '/wp-json/wp/v2/posts',
  //   params: { per_page: 10, page }
  // })

  // useEffect(() => {
  //   if (data && data.length > 0 && !loading) {
  //     setAllPosts(allPosts => [...allPosts, ...data])
  //   }
  // }, [data, loading])

  // useEffect(() => {
  //   if (page === 1 && response) {
  //     setMaxPage(response.headers['x-wp-totalpages']);
  //   }
  // }, [page, response])

  // const onLoadMore = () => setPage(page + 1);

  // const initialLoading = loading && page === 1;
  // const noData = error || !(allPosts && allPosts.length > 0);

  // let view;

  // if (initialLoading) {
  //   view = (
  //     <section className={`${classes.section} ${classes.landingSection}`}>
  //       <Loading loadingText="Loading publications, please wait..." />
  //     </section>
  //   )
  // } else if (noData) {
  //   view = (
  //     <section className={`${classes.section} ${classes.landingSection}`}>
  //       <Error errorText="There are no publications found" />
  //     </section>
  //   )
  // } else {
  //   const [featuredPost, ...otherPosts] = allPosts;
  //   view = (
  //     <Fragment>
  //       <section className={`${classes.section} ${classes.landingSection}`}>
  //         <Grid container alignItems="center">
  //           <Grid item lg={7} sm={6} xs={12}>
  //             <Hidden xsDown >
  //               <Paper
  //                 color="primary"
  //                 square className={`${classes.paper} ${classes.landingPaper}`}
  //                 elevation={0}
  //               >
  //                 <Typography variant="h2">
  //                   Our
  //                   <br />
  //                   Latest
  //                   <br />
  //                   Publication
  //                 </Typography>
  //               </Paper>
  //             </Hidden>
  //             <Hidden smUp>
  //               <Typography align="center" variant="h3">Our Latest Publication</Typography>
  //             </Hidden>
  //           </Grid>
  //           <Grid item lg={5} sm={6} xs={12}>
  //             <PublicationCard
  //               big={showFeaturedInBig}
  //               title={featuredPost.title}
  //               excerpt={featuredPost.excerpt}
  //               slug={featuredPost.slug}
  //               date={featuredPost.date}
  //             />
  //           </Grid>
  //         </Grid>
  //       </section>
  //       <section style={{ paddingTop: 80 }} className={classes.section}>
  //         <Typography
  //           variant="h3"
  //           color="textPrimary"
  //           className={classes.sectionTitle}
  //           align="center"
  //         >
  //           Other Publications
  //         </Typography>
  //         <PublicationList
  //           publications={otherPosts}
  //           disableMoreButton={page >= maxPage}
  //           loading={loading}
  //           onMoreButtonClick={onLoadMore}
  //           moreButtonType="load-more"
  //         />
  //       </section>
  //     </Fragment>
  //   )
  // }

  // return (
  //   <PageWrapper>
  //     {view}
  //   </PageWrapper>
  // )
}

export default Publications;