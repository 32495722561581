import React from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '64px',
    margin: '0 auto',
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
  heading: {
    marginBottom: 32
  }
}))

const Error = ({ errorText, showRetry, onRetry }) => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Typography color="textSecondary" variant="h5" className={classes.heading}>
        {errorText || 'Sorry, an error occured'}
      </Typography>
      {(showRetry && onRetry) && <Button variant="outlined" onClick={onRetry} color="primary">RELOAD</Button>}
    </section>
  )
}

export default Error;