import React, { useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import ContactUs from './ContactUs';
import AppBar from './AppBar';
import { useScrollTrigger, Slide, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: 24,
    textAlign: 'center',
    '& a': {
      color: theme.palette.primary.main,
    }
  }
}));

const PageWrapper = ({ children }) => {
  const { hash } = useLocation();

  useEffect(() => {
    const elementId = hash.replace('#', '');
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView()
    }
  }, [hash])

  const classes = useStyles();
  const showFloatingAppBar = useScrollTrigger({ disableHysteresis: true, threshold: 120 });

  return (
    <Fragment>
      <AppBar />
      <Slide direction="down" in={showFloatingAppBar}>
        <AppBar floating />
      </Slide>
      {children}
      <ContactUs />
      <div className={classes.footer}>
        <Typography variant="body2">
          Copyright © 2020 STRAT.O.SPHERE CONSULTING PTE LTD
          <br />
          Header and Footer Photo by <a href="https://unsplash.com/@overide?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Eugene Lim</a> on <a href="https://unsplash.com/@overide?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
        </Typography>
      </div>
    </Fragment>
  )
}

export default PageWrapper;
