import React, { useState } from 'react';

import { makeStyles, IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { Menu } from '@material-ui/icons'
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  navMenu: {
    borderBottom: '1px solid transparent',
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  activeNav: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.contrastText
    }
  }
}))

const MobileDrawer = () => {
  const { pathname, hash } = useLocation();
  const isHome = pathname === '/';
  const [showDrawer, setShowDrawer] = useState(false);
  const classes = useStyles();

  const openDrawer = () => setShowDrawer(true);
  const closeDrawer = () => setShowDrawer(false);

  return (
    <>
      <IconButton onClick={openDrawer} color="primary">
        <Menu />
      </IconButton>
      <Drawer
        anchor="top"
        open={showDrawer}
        onBackdropClick={closeDrawer}
        onClose={closeDrawer}
      >
        <List>
          <ListItem button component="a" onClick={closeDrawer} className={`${classes.navMenu} ${pathname === '/' && (!hash || hash === '#main') ? classes.activeNav : ''}`} href={isHome ? '/#main' : '/'}>
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem button component="a" onClick={closeDrawer} className={`${classes.navMenu} ${hash === '#who-we-are' ? classes.activeNav : ''}`} href="/#who-we-are">
            <ListItemText>Who We Are</ListItemText>
          </ListItem>
          <ListItem button component="a" onClick={closeDrawer} className={`${classes.navMenu} ${hash === '#capabilities' ? classes.activeNav : ''}`} href="/#capabilities">
            <ListItemText>Capabilities</ListItemText>
          </ListItem>
          <ListItem button component="a" onClick={closeDrawer} className={`${classes.navMenu} ${(pathname.startsWith('/publications') || hash === '#publications') ? classes.activeNav : ''}`} href={isHome ? '/#publications' : '/publications'}>
            <ListItemText>Publications</ListItemText>
          </ListItem>
          <ListItem button component="a" onClick={closeDrawer} className={`${classes.navMenu} ${hash === '#contact-us' ? classes.activeNav : ''}`} href="#contact-us">
            <ListItemText>Contact Us</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default MobileDrawer;