import React, { Fragment } from 'react';
import { Typography, makeStyles, Paper, Box, Hidden } from '@material-ui/core';
import axiosRetry from 'axios-retry';
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import parse from 'html-react-parser'


import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as AseanMap } from '../assets/asean-map.svg';
import { ReactComponent as AseanFlag } from '../assets/asean-flag.svg';
import PageWrapper from '../components/PageWrapper';
import EmbeddedPublications from '../components/EmbeddedPublications';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '64px 0',
    boxSizing: 'border-box',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 0',
    },
  },
  paper: {
    padding: '42px 64px',
    zIndex: 2
  },
  attribution: {
    margin: '0 64px'
  },
  link: {
    color: theme.palette.primary.main
  },
  landingSection: {
    minHeight: '90vh',
    padding: '128px 0',
    background: 'linear-gradient(rgba(58, 47, 17, 0.6), rgba(58, 47, 17, 0.6)), url("https://source.unsplash.com/w74T9SFYyHc/1920x1280") center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end'
  },
  landingPaper: {
    backgroundColor: '#EDBA3B',
    '& h3': {
      marginBottom: '32px'
    },
    alignSelf: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 600
    }
  },
  sectionTitle: {
    padding: '0 64px'
  },
  aseanMap: {
    flex: 1,
    marginLeft: -32,
    position: 'relative',
    zIndex: -1,
    [theme.breakpoints.only('md')]: {
      marginLeft: -64,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: -56,
      alignSelf: 'flex-start'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      alignSelf: 'center'
    }
  },
  aseanFlag: {
    flex: 1,
    marginRight: -32,
    position: 'relative',
    zIndex: -1,
    [theme.breakpoints.only('md')]: {
      marginRight: -16,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: -56,
      alignSelf: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
      alignSelf: 'center'
    }
  },
  rowContent: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& h3': {
        textAlign: 'center'
      }
    }
  }
}))

axiosRetry(axios, { retry: 3 });
const useAxios = makeUseAxios({
  axios: axios.create({ baseURL: process.env.REACT_APP_ADMIN_ENDPOINT })
})

const parseStratLogo = (text) => {
  const decodedText = text.replace(/&lt;/g, '<');
  return parse(decodedText, {
    replace: ({ name }) => {
      if (name === 'stratosphere-logo') {
        return <Logo height="auto" width={125} />
      }
    }
  })
}

const Home = () => {
  const classes = useStyles();
  const [{ data: sections = [] }] = useAxios({
    url: 'https://admin.stratosphereconsulting.com.sg/wp-json/wp/v2/sections',
    params: { id: '235,237,238' }
  })

  const [capabilities, whoWeAre, main] = sections;

  return (
    <PageWrapper>
      <Fragment>
        <section id="main" className={`${classes.section} ${classes.landingSection}`}>
          {main && <Paper
            color="primary"
            square className={`${classes.paper} ${classes.landingPaper}`}
            elevation={1}
          >
            <Typography variant="h3">{main?.title?.rendered}</Typography>
            <Typography variant="body1">{parseStratLogo(decodeURIComponent(main?.content?.rendered))}</Typography>
          </Paper>}
        </section>
        <section id="who-we-are" className={classes.section}>
          <Box display="flex" className={classes.rowContent}>
            <Hidden mdUp>
              <AseanMap height={300} className={classes.aseanMap} />
            </Hidden>
            <Box alignSelf="flex-end" flex="1">
              {whoWeAre && (
                <Fragment>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    align="right"
                    className={classes.sectionTitle}
                  >
                    {whoWeAre?.title?.rendered}
                  </Typography>
                  <Paper
                    color="primary"
                    square className={classes.paper}
                    elevation={1}
                  >
                    <Typography variant="body1">{parseStratLogo(whoWeAre?.content?.rendered)}</Typography>
                  </Paper>
                  <Typography variant="caption" color="textPrimary" className={classes.attribution}>
                    South East Asia map image is based on&nbsp;
                    <a className={classes.link} href="https://freevectormaps.com/world-maps/southeast-asia/WRLD-SEA-01-0003?ref=atr" target="__blank" rel="noopener noreferrer">freevectormaps.com</a>
                  </Typography>
                </Fragment>)
              }
            </Box>
            <Hidden smDown>
              <AseanMap height={600} preserveAspectRatio="xMinYMid meet" className={classes.aseanMap} />
            </Hidden>
          </Box>
        </section>
        <section id="capabilities" className={classes.section}>
          <Box display="flex" className={classes.rowContent}>
            <Hidden mdUp>
              <AseanFlag height={300} className={classes.aseanFlag} />
            </Hidden>
            <Hidden smDown>
              <AseanFlag height={500} preserveAspectRatio="xMaxYMid meet" className={classes.aseanFlag} />
            </Hidden>
            <Box alignSelf="center" flex="1">
              {capabilities && (
                <Fragment>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    className={classes.sectionTitle}
                  >
                    {capabilities?.title?.rendered}
                  </Typography>
                  <Paper
                    color="primary"
                    square className={classes.paper}
                    elevation={1}
                  >
                    <Typography variant="body1">{parseStratLogo(capabilities?.content?.rendered)}</Typography>
                  </Paper>
                </Fragment>)}
            </Box>
          </Box>
        </section>
        <section id="publications" style={{ paddingTop: 80 }} className={classes.section}>
          <Typography
            variant="h3"
            color="textPrimary"
            className={classes.sectionTitle}
            align="center"
          >
            Our Publications
          </Typography>
          <EmbeddedPublications />
        </section>
      </Fragment>
    </PageWrapper>
  )
}

export default Home;