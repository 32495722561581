import React, { forwardRef } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';

import { AppBar as MuiAppBar, Toolbar, Button, makeStyles, Hidden } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import MobileDrawer from './MobileDrawer';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: '4px 64px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  menus: {
    width: 560,
    display: 'flex',
    justifyContent: 'space-between'
  },
  navMenu: {
    padding: 0,
    borderRadius: 0,
    justifyContent: 'flex-start',
    borderBottom: '1px solid transparent',
    color: (props) => props.isHome ? 'white' : theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: 'transparent',
      borderBottom: '1px solid'
    }
  },
  activeNav: {
    borderBottom: `1px solid ${theme.palette.primary.main}`
  }
}))

const AppBar = forwardRef(({ floating }, ref) => {
  const { pathname, hash } = useLocation();
  const isHome = pathname === '/';
  const classes = useStyles({ isHome: isHome && !floating });

  return (
    <MuiAppBar
      position={floating ? 'fixed' : 'absolute'}
      color={floating ? 'white' : 'transparent'}
      elevation={floating ? 1 : 0}
      ref={ref}
    >
      <Toolbar className={classes.toolbar}>
        <a href={isHome ? '/#main' : '/'}>
          <Logo width={200} height="auto" />
        </a>
        <Hidden mdUp>
          <MobileDrawer />
        </Hidden>
        <Hidden smDown>
          <div className={classes.menus}>
            <Button
              className={`${classes.navMenu} ${hash === '#who-we-are' ? classes.activeNav : ''}`}
              href="/#who-we-are"
            >
              Who We Are
          </Button>
            <Button
              className={`${classes.navMenu} ${hash === '#capabilities' ? classes.activeNav : ''}`}
              href="/#capabilities"
            >
              Capabilities
          </Button>
            <Button
              className={`${classes.navMenu} ${(pathname.startsWith('/publications') || hash === '#publications') ? classes.activeNav : ''}`}
              href={process.env.REACT_APP_STRATSEA_HOMEPAGE}
              target="_blank"
            >
              Publications
          </Button>
            <Button
              className={`${classes.navMenu} ${hash === '#contact-us' ? classes.activeNav : ''}`}
              href="#contact-us"
            >
              Contact Us
          </Button>
          </div>
        </Hidden>
      </Toolbar>
    </MuiAppBar>
  )
})

export default AppBar;